<template>
  <div>
    <div class="contain1">
      <div>
        设备类型
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.equipmentType"
            class="m1150"
            placeholder="设备类型"
            @change="inquire"
        >
          <el-option
              v-for="item in equipmentTypeArray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
        行政区域
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.addrId"
            class="m1150"
            placeholder="行政区域"
            @change="inquire"
        >
          <el-option
              v-for="item in addrList"
              :key="item.id"
              :label="item.addrName"
              :value="item.id"
          >
          </el-option>
        </el-select>
        状态
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.generalStatus"
            class="m1150"
            placeholder="设备状态"
            @change="inquire"
        >
          <el-option
              v-for="item in statusArray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
        关键字
        <el-input
            size="mini"
            type="text"
            v-model="search.keyword"
            placeholder="请输入设备名称/所属用户/联系方式/地址"
            class="m1150 select230"
            style="width:300px"
        ></el-input>
        <el-button
            size="mini"
            type="primary"
            class="ml20"
            icon="el-icon-search"
            @click="inquire"
        >查询</el-button
        >
        <el-button
            size="mini"
            class="ml20"
            icon="el-icon-refresh"
            @click="refresh"
        >重置</el-button
        >
      </div>
    </div>

    <div class="contain2">
      <div class="btn-top">
        <div class="right">
          <el-button
              size="mini"
              type="primary"
              class="ml20"
              icon=""
              @click="openForm"
          >添加</el-button
          >
        </div>
      </div>
      <el-table
          :data="tableData"
          :header-cell-style="{ background: '#f5f7fa' }"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="55"
        >
        </el-table-column>
        <el-table-column prop="equipmentName" label="设备编号"> </el-table-column>
        <el-table-column prop="userType" label="设备类型">
          <template slot-scope="scope">
            {{scope.row.equipmentType == 1?'流量计':''}}
            {{scope.row.equipmentType == 2?'燃气表':''}}
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="所属用户"> </el-table-column>
        <el-table-column prop="userPhone" label="联系方式"> </el-table-column>
        <el-table-column prop="addrName" label="行政区域"> </el-table-column>
        <el-table-column prop="location" label="设备地址"></el-table-column>
        <el-table-column prop="installTime" label="安装时间"></el-table-column>
        <el-table-column prop="lastMaintainTime" label="最后维护时间"></el-table-column>
        <el-table-column label="状态">
            <template slot-scope="scope">
              {{scope.row.generalStatus == 1?'启用':'禁用'}}
            </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
            >编辑</el-button
            >
            <el-button
                type="text"
                size="small"
                style="color: red"
                 v-if="scope.row.generalStatus == 0"
                @click="delRow(scope.row)"
            >删除</el-button
            >
            <el-button
                type="text"
                size="small"
                style="color: red"
                v-if="scope.row.generalStatus == 1"
                @click="changeStatus(scope.row.id,0)"
            >停用</el-button
            >
            <el-button
                type="text"
                size="small"
                style="color: green"
                v-if="scope.row.generalStatus == 0"
                @click="changeStatus(scope.row.id,1)"
            >启用</el-button
            >
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                size="small"-->
            <!--                style="color: yellowgreen"-->
            <!--                @click="goCheckBefore(scope.row.id)"-->
            <!--            >历次安检</el-button-->
            <!--            >-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="text-align: center; margin: 15px 0 100px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.current"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
      <!-- 弹窗 -->
      <el-dialog
          @closed="reset"
          :title="formTitle === 1 ? '添加档案' : '编辑档案'"
          :visible.sync="dialogFormVisible"
          :close-on-click-modal="false"
          width="30%"
      >
        <el-form
            :model="form"
            ref="ruleForm"
            label-width="100px"
            size="medium"
            :rules="rules"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="设备号" prop="equipmentName">
                <el-input
                    v-model="form.equipmentName"
                    placeholder="请填写设备号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="所属用户" prop="userId">
                <el-select
                    clearable
                    filterable
                    v-model="form.userId"
                    placeholder="所属用户"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in userArray"
                      :key="item.id"
                      :label="item.userName"
                      :value="item.id.toString()"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="安装时间" prop="installTime">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.installTime"
                    type="date"
                    placeholder="请选择选择日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="使用时间" prop="useTime">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.useTime"
                    type="date"
                    placeholder="请选择使用时间"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="设备类型" prop="equipmentType">
                <el-select
                    clearable
                    filterable
                    v-model="form.equipmentType"
                    placeholder="请选择设备类型"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in equipmentTypeArray"
                       :key="item.value"
                      :label="item.name"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
<!--              <el-form-item label="设备地址" prop="location">-->
<!--                <el-input-->
<!--                    @focus="showModel = true"-->
<!--                    v-model="form.location"-->
<!--                    placeholder="请输入设备地址"-->
<!--                ></el-input>-->
<!--              </el-form-item>-->
              <el-form-item label="详细地址" prop="location">
                <el-input
                   
                    v-model="form.location"
                    placeholder="请输入详细地址"
                >
                
                 <i
                slot="suffix"
                @click="showModel = true"
                class="el-icon-map-location"
                style="
                    font-size: 24px;
                    color: #409eff;
                    vertical-align: middle;
                    margin-left: 10px;
                "
                ></i>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm" v-if="formTitle === 1"
          >确 定</el-button
          >
          <el-button type="primary" @click="editForm" v-if="formTitle === 2"
          >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
          :visible.sync="showModel"
          :show-close="false"
          :close-on-click-modal="false">
        <mapDialog v-if="showModel" :form="form"  @closeModal="closeModel"></mapDialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mapDialog from './mapDialog'
import {
  checkUserSelect,
  checkUserSave,
  checkUserUpdate,
  checkUserDelete,
  selectByExcel,
  downloadUserExcel,
  selectAddrPullDown,updateStatus,
  selectDictListByPid,selectUserPullDown
} from "../../RequestPort/device/deviceList.js";
import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  name: "userFile",
  components:{mapDialog},
  data() {
    return {
      search: {
        equipmentType: "",
        keyword: "",
        current: 1,
        size: 100,
        generalStatus:'',
      },
      findObj: {}, // 查询和翻页对象
      total: 0,
      formTitle: 1,
      dialogFormVisible: false,
      form: {
        "equipmentCode": "",
        "equipmentName": "",
        "equipmentType": "",
        "installTime": "",
        "location": "",
        "useTime": "",
        "userId": "",
        locationCoord:'',
      },
      tableData: [],
      rules: {
        equipmentCode: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
        ],
        equipmentName:[
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        equipmentType:[
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        installTime:[
          { required: true, message: "请选择安装日期", trigger: "change" },
        ],
        location:[
          { required: true, message: "请输入详细地址", trigger: "change"},
        ],
        useTime:[
          { required: true, message: "请选择使用时间", trigger: "change" },
        ],
        userId:[
          { required: true, message: "请选择所属用户", trigger: "change" },
        ]
      },
      chosedItems: [],
      addrList: [],
      baseURL: "",
      ToLeadBool: false,
      changeTypeList: [],
      houseTypeList: [],
      userArray:[

      ],
      equipmentTypeArray:[
        {name:'流量计',value:1},
        {name:'燃气表',value:2},
      ],
      statusArray:[
        {name:'禁用',value:0},
        {name:'启用',value:1},
      ],
      showModel:false,
    };
  },
  mounted() {
    this.objCopy()
    this.getAddrList();
    this.getUserList();
    this.getDict(353).then(data => {
      this.changeTypeList = data
    })  // 改造类型
    this.getDict(366).then(data => {
      this.houseTypeList = data
    })  // 房屋类型
    selectUserPullDown().then((e)=>{
      console.log(e)
      this.userArray = e.data
    })
    this.baseURL = this.$service.defaults.baseURL;
  },
  methods: {
    changeStatus(id,status){
      updateStatus({id:id,generalStatus:status}).then((e)=>{
        this.getUserList()
      })
    },
    closeModel(address,lnglat){
      if (!address && !lnglat) {
        this.showModel = false
      } else {
        this.form.locationCoord = lnglat.toString()
        this.form.location = address
        this.showModel = false
        this.$forceUpdate()
      }
    },
    getUserList() {
      checkUserSelect(this.findObj).then((res) => {
        const { records, total } = res.data;
        this.tableData = records;
        this.total = total;
        if (records.length === 0 && res.data.pages >= 1) {
          this.search.current -= 1;
          this.objCopy()
          this.getUserList();
        }
      });
    },
    objCopy () {
      for (let key in this.search){
        this.findObj[key] = this.search[key];
      }
    },
    goCheckBefore(id) {
      this.$router.push({
        path: "checkBefore",
        query: {
          id: id,
        },
      });
    },
    exportUserExcel() {
      let data = {};
      for (let key in this.search) {
        if (key != "current" && key != "size") {
          data[key] = this.search[key];
        }
      }
      selectByExcel(data).then((res) => {
        if (res) {
          const blob = new Blob([res], { type: "application/x-xls" });
          // const fileName = "民用用户数据.xls";
          const fileName = "my" + getNowFormatDate() + ".xls";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        }
      });
    },
    ToLeadClick() {
      this.ToLeadBool = !this.ToLeadBool;
    },
    handleSuccess(res) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.getUserList();
      } else {
        this.$message.error(res.msg);
      }
      this.ToLeadBool = false;
    },
    exportUserFile() {
      downloadUserExcel({ type: this.search.usertype }).then((res) => {
        window.location.href = res.data;
        this.ToLeadBool = false;
      });
    },
    refresh() {
      this.search = {
        addr: "",
        keyword: "",
        current: 1,
        size: 100,
        equipmentType: "",
        generalStatus:'',
        addrId:''
      };
      this.objCopy()
      this.getUserList();
    },
    delItems() {
      if (this.chosedItems.length === 0) {
        this.$message.error("请先勾选要删除的数据");
        return false;
      }
      let ids = [];
      this.chosedItems.forEach((item) => {
        ids.push(item.id);
      });
      this.$confirm(`是否确认删除这${ids.length}条数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        checkUserDelete({ id: ids.toString() }).then((res) => {
          this.$message.success(res.msg);
          this.getUserList();
        });
      });
    },
    handleSelectionChange(e) {
      this.chosedItems = e;
    },
    editForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          checkUserUpdate(this.form).then((res) => {
            this.$message.success(res.msg);
            if (res.code == 200) {
              this.getUserList();
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    saveForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          checkUserSave(this.form).then((res) => {
            this.$message.success(res.msg);
            if (res.code == 200) {
              this.getUserList();
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    reset() {
      if (this.$refs.ruleForm !== undefined) {
        this.$refs["ruleForm"].resetFields();
      }
      if (this.form.id) delete this.form.id;
      if (this.form.deleteflag) delete this.form.deleteflag;
    },
    openForm() {
      this.formTitle = 1;
      this.dialogFormVisible = true;
      this.reset();
    },
    edit(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.formTitle = 2;
        let keys = Object.keys(row);
        keys.forEach((item) => {
          this.form[item] = row[item];
        });
      });
    },
    delRow(row) {
      this.$confirm("是否删除此条数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        checkUserDelete({ id: row.id }).then((res) => {
          this.$message.success(res.msg);
          this.getUserList();
        });
      });
    },
    inquire() {
      this.search.current = 1;
      this.objCopy()
      this.getUserList();
    },
    indexMethod(index) {
      return (this.search.current - 1) * this.search.size + index + 1;
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getUserList();
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getUserList();
    },
    /**
     * 所属行政区字典
     */
    getAddrList() {
      selectAddrPullDown().then((res) => {
        this.addrList = res.data;
      });
    },
    async getDict(id) {
      let data = await selectDictListByPid({pid: id}).then(res => {
        return res.data
      })
      return Promise.resolve(data)
    }
  },
};
</script>

<style scoped lang="less">
::v-deep.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>